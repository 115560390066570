.screen {
  width: 250px;
  min-width: 250px;
  height: 140px;
  background-color: #eff1f4;
  float: left;
}
.screen .leftBorder {
  border-radius: 6px;
  width: 3px;
  height: 136px;
  margin: 2px;
  float: left;
}
.screen .content {
  margin-left: 11px;
  width: 229px;
  position: relative;
}
.screen .contentHeading {
  font-size: 16px;
  color: #2d3242;
  letter-spacing: 0.3px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.screen .contentParagraphs {
  display: flex;
  align-items: center;

  font-size: 11px;
  color: #2d3242;
  letter-spacing: 0.25px;
  line-height: 13px;
  margin-bottom: 8px;
}
.screen .contentParagraphs > * {
  margin-right: 4px;
}
.screen .contentParagraphs.version--old {
  color: red;
}
.screen .contentParagraphs.version--old .version__alert {
  margin-left: 5px;
}
.screen .screenMenu {
  margin-top: 10px;
}
