#dropdownPresentationBuilderOptions {
    border: none;
    margin-left: 15px;
}

.builder-container {
    padding: 10px;
}

.presentation-name-holder {
    position: relative;
    float: left;
    min-width: 300px;
}


.presentation-name-holder .edit-icon {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
}

.presentation-name-holder:hover .edit-icon {
    display: block;
}

.presentation-name-holder input {
    padding-bottom: 1px;
    min-width: 300px;
}

.presentation-name-holder:hover input {
    border-bottom: 1px solid #2D7E9A;
    padding-bottom: 0;
}

.presentation-name-holder.has-error input {
    border-bottom: 1px solid red;    
    padding-bottom: 0;
}

.btn-picker {
    padding: 0 0 0 8px;
}

.bottom-controls label {
    /*font-family: OpenSans;*/
    font-size: 12px;
    color: #929D9D;
    line-height: 12px;
    font-weight: normal;
}

.property-info {
    /*font-family: OpenSans;*/
    font-size: 12px;
    color: #929D9D;
    line-height: 12px;
    margin-top: 5px;
}


.timeline-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.timeline-header {
    background: linear-gradient(-179deg, #32596D 0%, #183F4A 100%);
    display: flex;
    flex-direction: row;
}

.timeline-objects-holder {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: row;
}

.timeline-objects-header {
    width: 200px;
    min-width: 200px;
    overflow: visible;
}

.timeline-graph {
    width: 100%;
    height: 100%;
    padding-left: 3px;
    overflow: auto
}

.timeline-duration {
    height: 40px;
    padding-left: 5px;
    width: 200px;
    min-width: 200px;
    z-index: 10;
}

.timeline-scale {
    background: #ddd;
    width: 100%;
    border-bottom: 1px solid #cecece;
    position: relative;
}

.timeline-container.collapsed .timeline-scale {
    display: none;
}

.timeline-scale .tick {
    position: absolute;
    bottom: 2px;
}

.timeline-scale .tick:after {
    content: '';
    position: absolute;
    left: 3px;
    bottom: -3px;
    width: 1px;
    height: 3px;
    background-color: black;
}

.timeline-axis {
    position: absolute;
    border-bottom: 1px solid black;
    left: 3px;
    right: 0;
    bottom: 1px;
}

.timeline-object-name {
    height: 40px;
    border-right: 1px solid #cecece;
    line-height: 40px;
    padding-right: 5px;
    text-transform: capitalize;
    padding-left: 5px;
    border-bottom: 1px solid #CECECE;
}

.timeline-object {
    height: 40px;
    padding-top: 4px;
    border-bottom: 1px solid #CECECE
}

.timeline-object-name.selected {
    background: #F6F6F6;
}

.timeline-object-name:hover {
    padding-left: 6px;
}

.timeline-object-name.selected .name {
    font-weight: bold;
}

.timeline-anim {
    color: white;
    white-space: nowrap;
    background-color: #ccc;
    font-size: 11px;
    border-radius: 3px;
}

.timeline-anim-entry {
}

.timeline-anim-exit {
}


.opacity-slider {
    width: 120px;
    float: right;
    margin-top: 10px;
    margin-bottom: 5px;
}

.font-size-slider {
    width: 100px;
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 5px;
}


.btn-green-animation {
    border: 1px solid white;
    animation: btn-green-animation 5s;
    -moz-animation: btn-green-animation 5s infinite; /* Firefox */
    -webkit-animation: btn-green-animation 5s infinite; /* Safari and Chrome */
}

@-moz-keyframes btn-green-animation /* Firefox */
{
    0%   { border-color:green; color: green; background-color: white; }
    50%  { border-color:white; color: white; background-color: green; }
    100% { border-color:green; color: green; background-color: white; }
}

@-webkit-keyframes btn-green-animation /* Safari and Chrome */
{
    0%   { border-color:green; color: green; background-color: white; }
    50%  { border-color:white; color: white; background-color: green; }
    100% { border-color:green; color: green; background-color: white; }
}


.readonly-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.3)
}