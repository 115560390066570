.ui-label {
  width: 100%;

  font-size: 10px;
  color: #929d9d;
  letter-spacing: 0;
  line-height: 12px;
  text-transform: uppercase;
  font-weight: normal;
}

.ui-label .ui-input {
  margin-top: 5px;
}

.ui-label .input-group,
.ui-label .input {
  text-transform: initial;
}

.ui-input {
  color: #555;
  font-size: 14px;
  font-weight: 400;
}
