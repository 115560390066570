
.login-container {
  padding: 20px;

  position: relative;
  top: calc(50% - 50px);
  transform: translate(0, -50%);

  width: 400px;
  margin: 0 auto;

  display: flex;
  flex-direction: column
}

.login-container > svg {
    align-self: center;
    margin-bottom: 20px;
  }

.login-container h3 {
    margin-top: 0;
  }

@media screen and (max-width: 639px) {
  .login-container {
    width: 100%;
  }
}