.modal-body {
  max-height: 60vh;
  overflow: auto;
}

.loading-overlay {
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

span.ui-icon.glyphicon-trash {
  color: red;
}

.ui-icon + .ui-icon {
  margin-left: 10px;
}

.ui-label {
  width: 100%;

  font-size: 10px;
  color: #929d9d;
  letter-spacing: 0;
  line-height: 12px;
  text-transform: uppercase;
  font-weight: normal;
}

.ui-label .ui-input {
  margin-top: 5px;
}

.ui-label .input-group,
.ui-label .input {
  text-transform: none;
  text-transform: initial;
}

.ui-input {
  color: #555;
  font-size: 14px;
  font-weight: 400;
}

.modal-fullscreen-holder {
  overflow: hidden;
}

.media-item-picker {
  /* For specificity */
}

.media-item-picker.modal-lg {
  width: 95%;
}

.media-item-picker {
  height: 90%;
}

.media-item-picker .modal-content {
  height: 100%;

  display: flex;
  flex-direction: column;
}

.media-item-picker .modal-content .modal-body {
  overflow-y: auto;

  display: flex;
  flex-direction: column;
}

.media-item-picker .modal-content .modal-body .tab-content {
  height: 100%;
}

.media-item-picker .modal-content .modal-body .tab-content .tab-pane {
  height: 100%;
  padding-top: 20px;
}

.media-item-picker .modal-content .modal-body .tab-content .tab-gallery {
  display: flex;
  flex-direction: column;
}

.media-item-picker
  .modal-content
  .modal-body
  .tab-content
  .tab-gallery
  .search-box {
  max-width: 300px;
  width: 100%;
}

.media-item-picker
  .modal-content
  .modal-body
  .tab-content
  .tab-gallery
  .gallery {
  height: 100%;
  margin-top: 15px;
  overflow-y: auto;
}

.media-item-picker .modal-content .modal-footer {
  margin-top: auto;
}

.media-item .thumb-holder {
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
}

.media-item .thumbnail {
  height: 60px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

#dropdownPresentationBuilderOptions {
    border: none;
    margin-left: 15px;
}

.builder-container {
    padding: 10px;
}

.presentation-name-holder {
    position: relative;
    float: left;
    min-width: 300px;
}


.presentation-name-holder .edit-icon {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
}

.presentation-name-holder:hover .edit-icon {
    display: block;
}

.presentation-name-holder input {
    padding-bottom: 1px;
    min-width: 300px;
}

.presentation-name-holder:hover input {
    border-bottom: 1px solid #2D7E9A;
    padding-bottom: 0;
}

.presentation-name-holder.has-error input {
    border-bottom: 1px solid red;    
    padding-bottom: 0;
}

.btn-picker {
    padding: 0 0 0 8px;
}

.bottom-controls label {
    /*font-family: OpenSans;*/
    font-size: 12px;
    color: #929D9D;
    line-height: 12px;
    font-weight: normal;
}

.property-info {
    /*font-family: OpenSans;*/
    font-size: 12px;
    color: #929D9D;
    line-height: 12px;
    margin-top: 5px;
}


.timeline-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.timeline-header {
    background: linear-gradient(-179deg, #32596D 0%, #183F4A 100%);
    display: flex;
    flex-direction: row;
}

.timeline-objects-holder {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: row;
}

.timeline-objects-header {
    width: 200px;
    min-width: 200px;
    overflow: visible;
}

.timeline-graph {
    width: 100%;
    height: 100%;
    padding-left: 3px;
    overflow: auto
}

.timeline-duration {
    height: 40px;
    padding-left: 5px;
    width: 200px;
    min-width: 200px;
    z-index: 10;
}

.timeline-scale {
    background: #ddd;
    width: 100%;
    border-bottom: 1px solid #cecece;
    position: relative;
}

.timeline-container.collapsed .timeline-scale {
    display: none;
}

.timeline-scale .tick {
    position: absolute;
    bottom: 2px;
}

.timeline-scale .tick:after {
    content: '';
    position: absolute;
    left: 3px;
    bottom: -3px;
    width: 1px;
    height: 3px;
    background-color: black;
}

.timeline-axis {
    position: absolute;
    border-bottom: 1px solid black;
    left: 3px;
    right: 0;
    bottom: 1px;
}

.timeline-object-name {
    height: 40px;
    border-right: 1px solid #cecece;
    line-height: 40px;
    padding-right: 5px;
    text-transform: capitalize;
    padding-left: 5px;
    border-bottom: 1px solid #CECECE;
}

.timeline-object {
    height: 40px;
    padding-top: 4px;
    border-bottom: 1px solid #CECECE
}

.timeline-object-name.selected {
    background: #F6F6F6;
}

.timeline-object-name:hover {
    padding-left: 6px;
}

.timeline-object-name.selected .name {
    font-weight: bold;
}

.timeline-anim {
    color: white;
    white-space: nowrap;
    background-color: #ccc;
    font-size: 11px;
    border-radius: 3px;
}

.timeline-anim-entry {
}

.timeline-anim-exit {
}


.opacity-slider {
    width: 120px;
    float: right;
    margin-top: 10px;
    margin-bottom: 5px;
}

.font-size-slider {
    width: 100px;
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 5px;
}


.btn-green-animation {
    border: 1px solid white;
    animation: btn-green-animation 5s;
    -moz-animation: btn-green-animation 5s infinite; /* Firefox */
    -webkit-animation: btn-green-animation 5s infinite; /* Safari and Chrome */
}


.readonly-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.3)
}
.playlist-position-holder > span {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border: 1px solid #ddd;
  text-align: center;
  margin-right: 2px;
  cursor: pointer;
}
.playlist-position-holder > span:hover {
  background-color: #aaa;
}
.playlist-position-holder > span.selected {
  background-color: #ccc;
  color: blue;
  cursor: default;
}

.playlist-name {
  font-family: 'Open Sans';
  font-size: 30px;
  color: #2d7e9a;
  letter-spacing: 0;
  line-height: 30px;
}

.playlist-info-cell {
  padding: 30px 0 30px 32px;
  float: left;
}
.playlist-info-cell:first-child {
  padding: 30px 0 30px 41px;
}

.playlist-info-cell .value {
  display: block;
  font-family: 'Open Sans';
  font-size: 16px;
  color: #2d7e9a;
  letter-spacing: 0;
  line-height: 30px;
}

.playlist-info-cell select {
  height: 30px;
  width: 166px;
  background: #ffffff;
  border: 1px solid #c2c5cb;
  box-shadow: inset 0 0 7px 0 rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}

body > tr {
  display: table !important;

  td {
    padding: 8px;
    line-height: 1.428571429;
    vertical-align: top;
    border-top: 1px solid #ddd;
  }
}

.sorting-row {
  background-color: white;
  box-shadow: 1px 1px 10px black;
  height: 70px;
}

.sorting-row td {
  padding: 8px;
  vertical-align: middle;
}

.apps-list .app-group {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #dddddd;
}

.apps-list .app-group:last-child {
  border-bottom: none;
  margin-bottom: 100px;
}

.apps-list .app {
  width: 250px;
  height: 140px;
  background-color: #eff1f4;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 10px;

  position: relative;
}

.apps-list .app .background {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  pointer-events: none;
}

.apps-list .app a {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.apps-list .app a:hover {
  text-decoration: none;
}

.apps-list .app .title {
  flex-grow: 1;
}

.apps-list .app .app-name {
  font-size: 1.2em;
}

.apps-list .app .app-devices {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.apps-list .app .app-devices .bullet {
  color: green;
  font-size: 2em;
  margin-right: 5px;
}

.apps-list .app .app-type-icon {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  color: #337ab7;
  margin-left: auto;
}

.apps-list .app .app-type-icon i {
  font-size: 20px;
  margin-left: 10px;
  padding-bottom: -5px;
  display: inline-block;
}

.apps-list .app .edit-status {
  color: green;
  font-weight: bold;
  font-style: italic;
  font-size: 0.8em;
}

.apps-list .app .app-status {
  display: flex;
  flex-direction: row;
}

ul.apps-list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 20px;
}

ul.apps-list li {
  margin-bottom: 20px;
  padding: 10px 5px;
}

ul.apps-list li img {
  width: 100px;
  height: 70px;
  background-size: cover;
  margin-right: 12px;
}

.pages {
  width: 300px;
  height: 100%;
  overflow: auto;
  position: relative;
}

.pages-title {
  margin-top: 1em;
  margin-bottom: 1em;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}
/*
    PAGE EDITOR
*/

.page-editor {
  width: 100%;
  padding: 0px 12px;
  height: 100%;
  max-width: 700px;
  overflow: auto;
  position: relative;
}

.page-preview {
  padding: 20px;
}

@media (max-width: 1200px) {
  .page-editor {
    max-width: none;
    max-width: initial;
  }
  .page-preview {
    display: none;
  }
}

.page-editor .nav-tabs {
  margin-left: 15px;
}

.page-name-holder {
}

.page-name {
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 24px;
  padding-bottom: 1px;
  margin-top: 1em;
  margin-bottom: 1em;
  font-family: 'Open sans', Arial, Helvetica, sans-serif;
}

.page-name:hover {
  border-bottom: 1px solid #2d7e9a;
  padding-bottom: 0;
}

.page-name-holder .page-template {
  display: none;
}
.page-name:hover .page-template {
  display: inline;
}

.edit-block {
  margin-bottom: 3em;
  position: relative;
}

.edit-block .edit-block-commands {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.edit-block:hover .edit-block-commands {
  opacity: 1;
}

.edit-block-title {
  border-bottom: 1px solid #2d7e9a;
  padding-bottom: 0.4em;
  text-transform: lowercase;
  font-weight: 100;
  font-family: 'Open sans';
}

.edit-block legend {
  font-size: 12px;
  color: #929d9d;
  letter-spacing: 0;
  line-height: 12px;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
}

.edit-block-commands button {
  margin-left: 5px;
}

.btn-save-page {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1000;
}

.page__actions {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1000;
}

.page__actions .btn {
  margin-left: 4px;
}

.tab-content {
}

.subtle-label {
  font-size: 10px;
  color: #929d9d;
  letter-spacing: 0;
  line-height: 12px;
  text-transform: uppercase;
  font-weight: normal;
}

/*
    LOCALIZATION
*/

.unchanged {
  font-style: italic;
  opacity: 0.6;
}

/*
    GALLERY
*/

.upload,
.gallery-image,
.thumbnail-image {
  width: 140px;
  height: 140px;
  outline: none;
  background: transparent;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  margin: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: deepskyblue;
  background-color: #ffffff;
  text-align: center;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload:hover {
  background-color: #eaeaea;
}

.gallery-image,
.thumbnail-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.gallery-image button,
.thumbnail-image button {
  display: none;
  outline: none;
  border: none;
  background: rgba(0, 0, 0, 0.5);
  color: red;
  font-size: 2em;
  width: 139px;
  height: 139px;
}

.gallery-image:hover button,
.thumbnail-image:hover button {
  display: block;
}

.upload span {
  font-size: 70px;
  margin-top: -12px;
}

.upload-accept {
  background-color: deepskyblue;
  color: white;
}

.upload-reject {
  background-color: darkred;
  color: white;
}

.uploaded-thumb {
  float: left;
  max-width: 200px;
  max-height: 140px;
}

.btn-remove-highlight {
  background: transparent;
  border: none;
  color: gold;
}

.btn-highlight {
  background: transparent;
  border: none;
  color: gold;
}

.btn-remove-page {
  background: transparent;
  border: none;
  color: red;
}

.page-editor .children {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.page-editor .children li {
  margin-bottom: 8px;
  display: block;
  padding: 4px 8px;
}

.page-editor .children li .fa {
  margin-right: 8px;
}

.page-editor .tab-pane {
  margin-top: 20px;
  margin-left: 12px;
}

.template-selector .template {
  background-color: #fafafa;
  padding: 4px 12px;
  margin-bottom: 4px;
}

.template-selector .selected {
  font-weight: bold;
  background-color: #dedede;
}

.fancy .control-label {
  font-size: 10px;
  color: #929d9d;
  letter-spacing: 0;
  line-height: 12px;
  text-transform: uppercase;
}

.fancy .control-info {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 20px;
}

.fancy .checkbox label {
  font-size: 12px;
  line-height: 20px;
  color: #929d9d;
  letter-spacing: 0;
  text-transform: uppercase;
}

.preview-title {
  text-align: center;
  font-size: 1.4em;
}

.pca-hold {
  width: 299px;
}
.pca-hold .pca-main {
  display: block;
  position: relative;
  background-color: #c9c9c9;
  border: 8.97px solid #1d2d2d;
  border-top: 68.77px solid #1d2d2d;
  border-bottom: 70.564px solid #1d2d2d;
  border-radius: 33.787px;
  height: 613.548px;
  box-shadow: 0 0 0 2.99px #c6c7ca;
}
.pca-hold .pca-main:after {
  content: "";
  position: absolute;
  top: -38.87px;
  left: 55%;
  transform: translate(-50%, 0);
  width: 8.97px;
  height: 8.97px;
  background-color: #959595;
  border-radius: 100%;
  transform: translate(-53.82px, 0);
}
.pca-hold .pca-main:before {
  content: "";
  position: absolute;
  top: -14.95px;
  left: 55%;
  opacity: 1;
  width: 59.8px;
  height: 2.99px;
  transform: translate(-26.91px, -22.425px);
  border-radius: 2.99px;
  background-color: #959595;
}
.pca-hold .pca-inner {
  display: block;
}
.pca-hold .pca-sub {
  display: block;
  text-align: center;
  position: relative;
  height: 0;
}
.pca-hold .pca-sub .pca-bot {
  border-radius: 100%;
  width: 41.86px;
  height: 41.86px;
  display: block;
  margin: 0 auto;
  background-color: transparent;
  z-index: 2;
  box-shadow: 0 0 0 3.289px #636670;
  transform: translate(0, -56.212px);
}

.pages-tree {
  padding: 0;
  list-style-type: none;
  font-size: 1.1em;
}

.pages-tree li > a {
  padding: 4px 8px;
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pages-tree a:active,
.pages-tree a:hover,
.pages.tree a:focus,
.pages.tree a:visited {
  text-decoration: none;
}

.pages-tree .fa {
  margin-right: 8px;
}

.pages-tree .selected {
  color: black;
  font-weight: bold;
  background-color: rgb(246, 246, 246);
}

.pages-tree .page {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.pages-tree .page > .fa-link {
  margin-left: 10px;
}

.pages-tree .page-edited {
  font-style: italic;
}

.pages-tree .page-hidden {
  color: gray;
}

.pages-tree .page-edited:before {
  content: 'EDIT';
  font-weight: bold;
  color: green;
  float: right;
  font-size: 0.7em;
}

.screen {
  width: 250px;
  min-width: 250px;
  height: 140px;
  background-color: #eff1f4;
  float: left;
}
.screen .leftBorder {
  border-radius: 6px;
  width: 3px;
  height: 136px;
  margin: 2px;
  float: left;
}
.screen .content {
  margin-left: 11px;
  width: 229px;
  position: relative;
}
.screen .contentHeading {
  font-size: 16px;
  color: #2d3242;
  letter-spacing: 0.3px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.screen .contentParagraphs {
  display: flex;
  align-items: center;

  font-size: 11px;
  color: #2d3242;
  letter-spacing: 0.25px;
  line-height: 13px;
  margin-bottom: 8px;
}
.screen .contentParagraphs > * {
  margin-right: 4px;
}
.screen .contentParagraphs.version--old {
  color: red;
}
.screen .contentParagraphs.version--old .version__alert {
  margin-left: 5px;
}
.screen .screenMenu {
  margin-top: 10px;
}


.login-container {
  padding: 20px;

  position: relative;
  top: calc(50% - 50px);
  transform: translate(0, -50%);

  width: 400px;
  margin: 0 auto;

  display: flex;
  flex-direction: column
}

.login-container > svg {
    align-self: center;
    margin-bottom: 20px;
  }

.login-container h3 {
    margin-top: 0;
  }

@media screen and (max-width: 639px) {
  .login-container {
    width: 100%;
  }
}
.forgot-password-page {
  padding: 20px;

  position: relative;
  top: calc(50% - 50px);
  transform: translate(0, -50%);

  width: 400px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
}

.forgot-password-page > svg {
  align-self: center;
  margin-bottom: 20px;
}

.forgot-password-page h3 {
  margin-top: 0;
}

@media screen and (max-width: 639px) {
  .forgot-password-page {
    width: 100%;
  }
}

.reset-password-container {
  padding: 20px;

  position: relative;
  top: calc(50% - 50px);
  transform: translate(0, -50%);

  max-width: 300px;
  margin: 0 auto;
}

.reset-password-container h3 {
  margin-top: 0;
}

.add-user.glyphicon {
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #2f2f2f;
  padding: 4px;
  cursor: pointer;
}

.app {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 10px;
  float: left;
  width: 300px;
  height: 140px;
}
.app .app-name {
  display: block;
  font-size: 1.2em;
  line-height: 1.25em;
  margin-bottom: 0.5em;
}
.app .user {
  padding: 2px 4px;
  background-color: white;
  border: 1px solid #2f2f2f;
  border-radius: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
  font-size: 0.8em;
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  /* padding-top: 50px; */
  background: #f6f6f6;
}

a {
  cursor: pointer;
}

.loading {
  line-height: 60px;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -30px;
  text-align: center;
  width: 100%;
}

.center-box {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-left: -200px;
  height: 100px;
  line-height: 100px;
  margin-top: -50px;
}

.full-height {
  height: 100%;
}

#root {
  height: 100%;
  overflow: auto;
}

.padding0 {
  padding: 0;
}

.container,
.root-container {
  color: #2d7e9a;
  height: 100%;
}

.root-container {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.navbar {
  background-image: linear-gradient(-179deg, #32596d 0%, #183f4a 100%);
  height: 50px;
}

.navbar-inverse .navbar-brand {
  /*font-family: AzoSans-Regular;*/
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0.26px;
  line-height: 15px;
}

.navbar-inverse .navbar-nav > li > a {
  /*font-family: AzoSans-Regular;*/
  font-size: 13px;
  color: #929d9d;
  letter-spacing: 0.21px;
  line-height: 15px;
  text-transform: uppercase;
  padding-top: 18px;
  padding-bottom: 17px;
}

.navbar-inverse .navbar-nav > li > a.link-active {
  font-size: 13px;
  color: #00caca;
  letter-spacing: 0.21px;
  line-height: 15px;
}

.navbar-inverse .navbar-nav > li > a.link-active:after {
  content: ' ';
  height: 2px;
  width: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  border-bottom: 2px solid #00caca;
  box-shadow: 0 -2px 5px 0 rgba(0, 243, 255, 0.5);
}

#navigation-holder {
  padding: 0 45px 0 15px;
}

.main-section {
  padding: 15px;
  margin-right: 32px;
  margin-bottom: 32px;
  width: 220px;
  height: 50px;
  float: left;
}

.card-bg {
  background-image: linear-gradient(-180deg, #ffffff 0%, #eff1f4 100%);
  border: 1px solid #ffffff;
  box-shadow: 0 1px 1px 0 rgba(146, 157, 157, 0.5);
  border-radius: 3px;
}

.card-bg:hover {
  box-shadow: 0 1px 1px 0 rgba(146, 157, 157, 1);
}

.main-section a {
  line-height: 15px;
  font-size: 15px;
  text-align: center;
}

.addmediathumb {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px;
  text-align: center;
  width: 100px;
  height: 100px;
  overflow: hidden;
}

.media-thumb {
  width: 100px;
  height: 70px;
  background-size: cover;
}

.btn-fancy {
  color: #929d9d;
  background-image: linear-gradient(-180deg, #ffffff 0%, #eff1f4 100%);
  border: 1px solid #ffffff;
  box-shadow: 0 1px 2px 0 rgba(146, 157, 157, 0.5);
  border-radius: 3px;
}

.btn-fancy:hover,
.btn-fancy:active,
.btn-fancy:focus,
.btn-fancy:visited {
  outline: none;
}

.btn-transparent {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}

.btn-transparent:hover,
.btn-transparent:active,
.btn-transparent:focus,
.btn-transparent:visited {
  outline: none;
}

.btn-transparent:disabled {
  color: #a0a0a0;
  cursor: not-allowed;
}

.navbar .btn {
  line-height: 15px;
  margin: 10px 0 5px 10px;
  color: white;
  background: transparent;
  border: 1px solid white;
}

.table-shadow {
  background: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 3px;
}

.table-shadow th {
  opacity: 0.54;
  /*font-family: Roboto-Medium;*/
  font-size: 12px;
  font-weight: normal;
  color: #000000;
  letter-spacing: 0;
}

.table-shadow td {
  color: black;
  font-weight: normal;
  font-size: 13px;
}

.thumb {
  width: 100px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.thumb img {
  max-width: 100px;
  max-height: 70px;
}

.table > tbody > tr > td,
.table > tbody > tr > th {
  vertical-align: middle;
}

.top-options {
  padding: 20px 0;
}

.drag-handle {
  cursor: pointer;
}

.mr-20 {
  margin-right: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.pb-0 {
  padding-bottom: 0;
}

/*/////////////////////////////////////////////////////////////////////////
                        Digital Signage CSS
////////////////////////////////////////////////////////////////////////*/
.app-holder {
  padding-top: 50px;
}

.app-holder,
.digital-signage-holder {
  width: 100%;
  background: #f6f6f6;
  color: #fff;
  height: 100%;
}

.digital-content-holder {
  padding: 0 20px;
  height: 100%;
}

.builder-container {
  height: 100%;
}

.canvas-holder input {
  color: #000;
}

.canvas-holder {
  height: calc(100% - 150px);
  transition: height 0.5s;
}

.canvas-holder.stretch {
  height: 100%;
}

.canvas-container {
  margin: auto;
}

.canvas-section-holder {
  width: calc(100% - 351px);
  float: left;
  background: #999 url(data:image/gif;base64,R0lGODlhFAAUAIAAAP///+/v7yH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ4bXAuZGlkOjdjNjU2MDYyLTE2NDAtNDY2Yi04YzkxLTc1N2U0MjBkNzI5NiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpGMTlGMTdGMEVBMUYxMUU2ODBBMEI1MUYwRThBNzM1OSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpGMTlGMTdFRkVBMUYxMUU2ODBBMEI1MUYwRThBNzM1OSIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgMjAxNyBNYWNpbnRvc2giPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo3YzY1NjA2Mi0xNjQwLTQ2NmItOGM5MS03NTdlNDIwZDcyOTYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6N2M2NTYwNjItMTY0MC00NjZiLThjOTEtNzU3ZTQyMGQ3Mjk2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEAAAAAAAsAAAAABQAFAAAAiiEj6HL6MBieEkueuzFUE/ufVjIdVpJgqGHsur5Wi08ujVNpbccS2UBADs=);
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  height: calc(100% - 65px);
}

.canvas-controls {
  width: 341px;
  float: left;
  margin-left: 10px;
  height: calc(100% - 65px);
}

.top-controls,
.bottom-controls {
  padding: 10px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.bottom-controls {
  overflow: auto;
  color: #929d9d;
  height: calc(100% - 60px);
}

.top-controls {
  margin-bottom: 15px;
}

.digital-content-holder .navbar-default {
  margin-bottom: 0;
}

.name-and-save {
  padding: 20px 0;
  margin-bottom: 10px;
}

.name-and-save .glyphicon-pencil {
  color: #929d9d;
  vertical-align: top;
}

.name-and-save input {
  background: none;
  border: none;
  outline: none;
  min-width: 300px;
  font-size: 20px;
  color: #2d7e9a;
  letter-spacing: 0;
  line-height: 30px;
}

.name-and-save .save-button {
  color: #929d9d;
  background-image: linear-gradient(-180deg, #ffffff 0%, #eff1f4 100%);
  border: 1px solid #ffffff;
  box-shadow: 0 1px 2px 0 rgba(146, 157, 157, 0.5);
  border-radius: 3px;
}

.name-and-save .save-button:hover {
  color: #344;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #2d7e9a;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #2d7e9a;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #2d7e9a;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #2d7e9a;
}

.single-channel {
  background-image: linear-gradient(-180deg, #ffffff 0%, #eff1f4 100%);
  border: 1px solid #ffffff;
  box-shadow: 0 1px 1px 0 rgba(146, 157, 157, 0.5);
  border-radius: 4px;
  margin-right: 32px;
  margin-bottom: 32px;
}

.channels-holder .col-md-3 {
  margin-bottom: 20px;
}

.modal-content {
  color: #000;
}

.modal-dialog-fullscreen {
  width: 80%;
  height: 80%;
  margin: 0;
  padding: 0;
  margin: auto;
  top: 10%;
}

.modal-dialog-fullscreen .modal-content {
  height: auto;
  min-height: 80%;
  border-radius: 0;
}

.schedule-day {
  display: inline-block;
  background-color: white;
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: black;
  text-align: center;
  border: 1px solid #eee;
}

.schedule-day.selected {
  background-color: green;
  color: white;
}

/*/////////////////////////////////////////////////////////////////////////
                        Timeline CSS
/////////////////////////////////////////////////////////////////////////*/
.timeline-container {
  background: #fff;
  border-radius: 3px;
  color: #929d9d;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  height: 200px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  transition: height 0.5s;
}

.timeline-container.collapsed {
  height: 40px;
  overflow: hidden;
}

.timeline-container.collapsed .timeline-objects-holder {
  display: none;
}

.timeline-container .collapse-btn {
  position: fixed;
  right: 10px;
  padding-top: 0px;
  display: inline-block;
  background: transparent;
  outline: none;
  z-index: 100;
}

.timeline-container .collapse-btn:active,
.timeline-container .collapse-btn:hover,
.timeline-container .collapse-btn:visited {
  color: red;
  outline: none;
}

.timeline-right-object {
  width: 100px;
  height: 32px;
  background: #2d7e9a;
  line-height: 32px;
  text-align: center;
  margin-left: 0;
  border-radius: 3px;
}

.selected-obj {
  background: #f6f6f6;
}

.timeline-left p:hover {
  cursor: pointer;
}

.timeline-duration-summ {
  margin: 12px auto;
}

/*/////////////////////////////////////////////////////////////////////////
                        Object properties and actions CSS
/////////////////////////////////////////////////////////////////////////*/
.top-controls button {
  margin-right: 10px;
}

.single-control-holder label,
.single-control-holder input,
.single-control-holder select {
  width: 70px;
}

.single-control-holder .textarea-label {
  float: left;
  margin-top: 5px;
}

.single-control-holder textarea {
  width: 200px;
  margin-right: 30px;
  border-radius: 2px;
  outline: none;
  padding: 2px 4px;
  border: 1px solid #cecece;
  background: #f5f5f5;
  color: dodgerblue;
  box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.18);
  font-size: 12px;
  color: #2d7e9a;
}

.single-control-holder {
  padding: 10px;
}

.image-btn,
.rect-btn,
.circle-btn,
.text-btn,
.line-btn {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
}

.btn-clear {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0;
}

.separator {
  display: inline-block;
  width: 1px;
  height: 2.5rem;
  background: #cecece;
  vertical-align: middle;
  margin-right: 10px;
  opacity: 0.7;
}

.image-btn {
  background: #f3f3f3 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAOCAYAAADJ7fe0AAAAAXNSR0IArs4c6QAAAfVJREFUKBWVUt9LFFEU/u6dmc00dI1WK0HXXjJYRcW3BAmf9FWIHnpZor+gQIgsQfoLfIigpQiqF4l6UbSHVHBTQtwoQrcfTqkr/mhxd1vX2Zk7t3sn7rC+tHpg5tzzne98893DkK6RJ/2cu09th4VwjKAEjq5pb065F67pUuBW96VQ5/nTx5AACjbTR9596jP3flyn0oESOHAYJpMpJHezZQVPGhpa62sqXMdt0BU7c1DEzVfzCAerYO7lca+3DZH6oGr/N1PVXVj7jUhdNe73tOBGRxixD99Uq2z2RVpC1fi8nUV8LY3YkonucF3ZYUXwRRrFNe5cacX0z130X2zAQKRRcbycLzq4+mIWDxeSh3BZ+DuRRfu5Wu+R59KwmYvoWByEEMyubuFyUwhtZ2t9iu9EIr/EQoemEnA59wnyMBpfRkCj4AJnLscj4aaU44uk9y3cHl/E93QOo++XfZGJlQ0spdIoCjcqcpaDqa+bqoQnIv+Pu28/ggq7jvjSnLmDL9sZrOxk8TyxCqtEQE5K/kuB24Irg3QMP7aagpWBjUxBkJkHHuUlr3emqoJt5QuDOqV4tp7Zj9ZUBlyKgJAVEpzgj2XTE4bGDUr+bYh7Haht2ZzxVK6gGbrxWo6g60GsT2DNnBP7KC4kh4AUGSczieGo+Rc5ds6qVQHU+wAAAABJRU5ErkJggg==) no-repeat
    center;
}

.rect-btn {
  background: #f3f3f3 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAANCAYAAABPeYUaAAAAAXNSR0IArs4c6QAAAE1JREFUKBVj1Kub9Z+BQsAC0h+sI8egIsxLslF33n5mWHvlEQPYEJABxtLCJBsC08AEY1BCjxqCGXrDLEzAiQ2U8sgBMH1gQ0BJlxIAAPsDEOfO0IrbAAAAAElFTkSuQmCC) no-repeat
    center;
}

.line-btn {
  background: #f3f3f3 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAATCAYAAACZZ43PAAAAAXNSR0IArs4c6QAAAf1JREFUOBFjYCAD6DfMUjNvnS0O0spEqn7jhmUiDP8Yd8jy802/d++eODMpBoQ2rGJ79e/zVn4OVu5+bxOZ///+qZLkglv/P81kZWHSnupv/p6RgeEHGxtbAdEG6NfNKmf4/y8aaPMpNmZGZRYWFh9FRcUPRBlg0Djb/z8jQ1uZnfYmMW52Z6DmEBUVlTsg77MQCgPDprn6//78W+qjKbvZRFYkEKg+A6h5P0wfXheYNsyX+Pvn32YNMb6zcYaKLkBNE9TV1WfDNINoYFhgBw4N8zne//9zUJibjW+anzkPIyPjRTU1NT8g/Q9ZB04XvP//ez4bM5PCJB/zH0ANH4SEhCLRNYMMwmqAXt3s+v//GQIn+ppeYmVmlGJnZ/cVFRX9jGwzjI1hgH7d7HCgZH2ts95WYS52W2Zm5kBgdD2AaUCnUQwwaJpvysDwf36IrvwGPQnBwP///6cAQ/wYuiZkPtwA84bZMv///N6oLyV0IlxP3gOoqF1DQ2MJsmJsbHAs6HUv4mb88vOIKDcH8xQ/UxFgYB0HhngIkP6PTROyGBPQmYyMX34s4WBlEZ/gYwJy0XNeXt5YYjSDDGLewCDbzPD/f+yMQIuLnCzMMkCNTsBAe4dsCz42CwMzw7oWJ0NVPnZWP2Aat1NWVn6KTwNWudu3bxsCsR9WSQKCAPOXjXC7/rNIAAAAAElFTkSuQmCC) no-repeat
    center;
}

.circle-btn {
  background: #f3f3f3 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAdRJREFUOBGdVM9LAkEYnZk1s8K6WJTayagQoqBLEFSeukmXAiMIIv+FPHRZ66T/QtEpEupmpw6RBUGXkOgHUdYlRakuIf3S3Zn2LazIarH5HWZ2Z997s/N97xtK6oQsC5Zkm+OqQqYIJV4dIkhWspFUkC+fyjLlZho1L4zIm/OMiriiCk+Ps/Wr29liA6ZQ/FTyxQ9HE2NZzsVKei2cqOZWhGblXXuGFDe4UBem+z00OOilrrbmaix5ff8mydusOLjLCUal7T7iDO/JcyWA9N3wABG7jYYiE8PM39WBpZqA8NKoj471umjs5DqUUYrALGKQMOA4QnB5NTBk+00EOCM62xxkwNUupR7zQ+7J4H3heP+KIbHICY5jRcQQAxYcjRzXNVAdJBY5MUBWZ3DKnHuhwVROA6iOObFWxMABFzZhRAiPUWIrZDNG52peY+YPjb4zQmkOZmtUQOdqrmcSE0dwLMz23wAHXLQOQ+/A9nDsf4XAARcamo8oVwmJwPY3z2+WtYAFB30HDd3ZhVTy0h2Y8Z09vfjhWDj3r4CI1iJlLuhOOrq8DmylamjAkiIS0cMLvnX+IOrlDGv4Bgyw4Bgb1rgZfaf9ZgyObegaMZQxN3Kx/QBwZPXfUoppMQAAAABJRU5ErkJggg==) no-repeat
    center;
}

.text-btn {
  background: #f3f3f3 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAQCAYAAADwMZRfAAAAAXNSR0IArs4c6QAAAjBJREFUOBF9Uk1oE0EUnp9NNqZp02hsSBuFbSnmYH9CsCWCLcVTwItiQMEeqrWI0oJnQdOAKB692gZET8Wb4EUEr+Iv8WYlHhpNQCIJtexmuzPP2U133babDuy+N+9975s33zyMDliT+ScJlcNbRMnt0r35l52gpFPCjLcQ3BRmCDO4fhAOd0pmH7+Sf9UrFQAUxQgzSQ4c+3hntuqF79hJ9U/lMiWk2ywCBJRta3NeBGasIwkgvLRwatggBDMTyAFdAwDPzj1JxvPF0zIlx6eVWFcyGq6bJAhgcGx5Zcby9/w8SQCMxbNDcRWLc7PJgaOumnmX77j7SNL3n8URxufPJQcSJiodj2BZoppVweFCJr9y2KnecfaRGLp6Q4mEakeCsgWRKEFTSszWQlY5mt1LYieteC6/5v8GzY2AREJBn68lroPFE4NmGP4t3ejaKf5aKiyMuokk92adN3J+QXArcyIo4ubnrKefyuz3X42KwMhooThZunv1nZ3cRSK0WDojWp9IRO28Y2ubGn3+udzeM2YK7JA4mqQKqxkOMDGjxA45lS5nWulDlBJoh+BS+uFa2E5bJOYQMcYK3bJkDEd77NwuGw740Uhfb1tDQCFDayzaAJxaXs1yxueEfjkqpvPK+CBN9UdQf89/SZgY1/c/66j44XuzoepWB4JtG2H0QKK+FxLnfEq8wQ8RfCSug96Ua/EWcHqxN7Rhn7Sp677X69WTTU3/Yg6gawUMboz9A3GAua996zUlAAAAAElFTkSuQmCC) no-repeat
    center;
}

.single-control-holder input,
.single-control-holder select {
  margin-right: 30px;
  border-radius: 2px;
  outline: none;
  padding: 2px 4px;
  border: 1px solid #cecece;
  background: #f5f5f5;
  color: dodgerblue;
  box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.18);
  font-size: 12px;
  color: #2d7e9a;
  line-height: 12px;
}

.bottom-properties div {
  width: 70px;
  float: left;
  text-align: center;
}

.bottom-properties p {
  margin: 0;
}

.bottom-properties div:last-child {
  margin-left: 30px;
}

.bottom-controls hr {
  margin: 10px auto;
  width: 90%;
}

.object-actions button {
  margin-right: 10px;
}

/*/////////////////////////////////////////////////////////////////////////
                        Media Gallery CSS
/////////////////////////////////////////////////////////////////////////*/

.media-upload {
  border: 1px dashed lightgray;
  padding: 10px;
  width: 80%;
  float: left;
}

.gallery-top-options {
  padding: 20px 0;
}

.gallery-holder {
  background: #fff;
  color: #000;
}

.gallery-holder .table td {
  vertical-align: middle;
}

.gallery-holder .table img {
  max-width: 120px;
  vertical-align: middle;
}

#user-actions {
  float: right;
  height: 50px;
  line-height: 50px;
}

#user-actions button {
  background-color: transparent;
  border: none;
  font-size: 15px;
  color: #ffffff;
  line-height: 15px;
}

#user-actions ul {
  padding: 10px;
}

#user-actions ul li a {
  color: white;
}

#logoutForm {
  width: 100%;
  float: none;
}

#logoutForm button {
  width: 100%;
  color: white;
}

.user-menu {
  background-image: linear-gradient(-179deg, #32596d 0%, #183f4a 100%);
  color: white;
}

form.dev {
  padding: 5px;
  background: #ddd;
  box-shadow: 5px 2px 2px #333;
}

.delete-media {
  margin-right: 10px;
  transition: transform 0.35s;
}

.delete-media:hover {
  cursor: pointer;
  transform: scale(1.5);
}

/*/////////////////////////////////////////////////////////////////////////
                        Playlists CSS
/////////////////////////////////////////////////////////////////////////*/

.playlists-top-options {
  padding: 20px 0;
}

#createScheduleForm {
  padding: 10px;
}

.rc-time-picker-input {
  border: none;
  background-color: transparent;
  padding: 0;
  height: auto;
}

.name {
  color: #2d7e9a;
}

.table-playlist tr.disabled {
  background-color: #eeeeee;
}

.duration-override-holder {
  display: none;
  position: absolute;
  background: #eee;
  border-radius: 3px;
  padding-left: 3px;
  top: 50%;
  left: 0;
  margin-top: -12px;
}

.duration-override-holder button {
  background: none;
  border: none;
  outline: none;
  color: red;
}

.duration-override-cell {
  position: relative;
}

.duration-override-cell:hover .duration-override-holder {
  display: block;
}

.duration-override-cell:hover .duration {
  display: none;
}

.duration-override {
  background: none;
  border: none;
  outline: none;
  width: 60px;
}

/*/////////////////////////////////////////////////////////////////////////
                        Screens CSS
/////////////////////////////////////////////////////////////////////////*/
.screen-left-border.green {
  background: #59be84;
}

.screen-left-border.yellow {
  background: yellow;
}

.screen-left-border.red {
  background: #be5959;
}

.screens-platform-icon {
  margin: 0;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  top: 110px;
}

.screen-content {
  float: none;
}

.screen-info {
  position: absolute;
  bottom: 0;
}

.screen-info img {
  margin-right: 4px;
  margin-top: -2px;
}

.screen-info .player-version {
  margin-right: 4px;
  display: inline-block;
  width: 12px;
  text-align: center;
  line-height: 11px;
  font-size: 11px;
}

/*/////////////////////////////////////////////////////////////////////////
                        Shell CSS
/////////////////////////////////////////////////////////////////////////*/

.notifications {
  position: fixed;
  right: 10px;
  bottom: 0;
  width: 400px;
}

.top-options {
  padding: 20px 0;
}

.recurring-schedule-holder button {
  background: transparent;
  padding: 5px 10px;
  border: 1px solid;
  border-radius: 3px;
  margin-right: 10px;
}

.recurring-schedule-holder button.pressed {
  background: blue;
  padding: 5px 10px;
  border: 1px solid;
  color: #fff;
  border-radius: 3px;
  margin-right: 10px;
}

/*/////////////////////////////////////////////////////////////////////////
                        Loading indicator CSS
                        http://tobiasahlin.com/spinkit/
/////////////////////////////////////////////////////////////////////////*/

.sk-cube-grid {
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #333;
  float: left;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube2 {
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube3 {
  animation-delay: 0.4s;
}
.sk-cube-grid .sk-cube4 {
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube5 {
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube6 {
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube7 {
  animation-delay: 0s;
}
.sk-cube-grid .sk-cube8 {
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube9 {
  animation-delay: 0.2s;
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    transform: scale3D(1, 1, 1);
  }
  35% {
    transform: scale3D(0, 0, 1);
  }
}

/*/////////////////////////////////////////////////////////////////////////
                            Responsive CSS
/////////////////////////////////////////////////////////////////////////*/
@media (max-width: 1200px) {
}

@media (min-width: 1200px) and (max-width: 1400px) {
}

@media (min-width: 1400px) and (max-width: 1600px) {
}

@media (min-width: 1600px) and (max-width: 1900px) {
}

.addmediathumb img {
  width: 51px;
  height: 51px;
}

.top-options {
  border-bottom: 1px solid #eee;
}

/*
    Modal forms
*/

.modal-fancy .modal-title {
  font-size: 26px;
  color: #2d3242;
  letter-spacing: 0;
  line-height: 26px;
}

.modal-fancy .modal-header {
  border-bottom: none;
}

.modal-fancy .modal-footer {
  border-top: none;
}

.modal-fancy .control-label {
  font-size: 10px;
  color: #929d9d;
  letter-spacing: 0;
  line-height: 12px;
  text-transform: uppercase;
}

.modal-fancy .checkbox label {
  font-size: 12px;
  line-height: 20px;
  color: #929d9d;
  letter-spacing: 0;
  text-transform: uppercase;
}

.modal-fancy .form-control {
  background: #ffffff;
  border: 1px solid #c2c5cb;
  box-shadow: inset 0 0 7px 0 rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}

.modal-fancy .modal-content {
  background-image: linear-gradient(-180deg, #ffffff 0%, #eff1f4 100%);
  border: 1px solid #ffffff;
  box-shadow: 0 1px 1px 0 rgba(146, 157, 157, 0.5);
  border-radius: 4px;
}

.rangeslider-horizontal {
  height: 4px;
  border-radius: 3px;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 13px;
  height: 13px;
  top: -4px;
  background: #2d7e9a;
}

.rangeslider-horizontal .rangeslider__handle:after {
  display: none;
}

.rangeslider-horizontal .rangeslider__fill {
  background: #929d9d;
}

.error-div {
  padding: 10px;
  margin-top: 20px;
  font-weight: bold;
  width: 50%;
}

span.media-draft {
  font-style: italic;
  font-weight: bold;
  color: orange;
}

.remove-button {
  background: transparent;
  outline: none;
}

.remove-button:focus,
.remove-button:active {
  outline: none;
}

.remove-button span {
  color: red;
  transform: scale(1);
  transition: transform 0.5s;
}

.remove-button span:hover {
  transform: scale(1.5);
}

/*
    BOOTSTRAP OVERRIDES
*/

.nav-tabs > li > a {
  text-transform: uppercase;
  /*font-family: AzoSans-Regular;*/
  font-size: 13px;
  color: #cecece;
  letter-spacing: 0;
  line-height: 26px;
  padding: 5px;
  min-width: 120px;
  text-align: center;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  border-bottom-color: #2d7e9a;
  color: #2d7e9a;
}

.ReactModalPortal {
  padding-top: 10px;
}

.ReactModal__Overlay {
  background-color: rgba(66, 66, 100, 0.5);
  z-index: 1060;
}

.modal-dialog {
  margin-top: 60px;
}

.pt-16 {
  padding-top: 16px;
}

.table tr.debug td {
  font-size: 0.8em;
  color: #333;
}

.line-loader {
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
}
.line-loader:before {
  display: block;
  position: absolute;
  content: '';
  left: -200px;
  width: 200px;
  height: 4px;
  background-color: #2980b9;
  animation: loading 2s linear infinite;
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}

/* .table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th, */
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  vertical-align: center;
}

.react-tooltip-lite {
  background: #333;
  color: white;
}

.react-tooltip-lite-arrow {
  border-color: #333;
}

.tooltip--error .react-tooltip-lite {
  color: #fafbfc;
  background-color: #cc0000;
}

.tooltip--error .react-tooltip-lite-arrow {
  border-color: #cc0000;
}


/*
  Ensure all tables have it correctly centered
  Override bootstrap style
*/
.table thead tr th {
  vertical-align: middle;
}

