.preview-title {
  text-align: center;
  font-size: 1.4em;
}

.pca-hold {
  width: 299px;
}
.pca-hold .pca-main {
  display: block;
  position: relative;
  background-color: #c9c9c9;
  border: 8.97px solid #1d2d2d;
  border-top: 68.77px solid #1d2d2d;
  border-bottom: 70.564px solid #1d2d2d;
  border-radius: 33.787px;
  height: 613.548px;
  box-shadow: 0 0 0 2.99px #c6c7ca;
}
.pca-hold .pca-main:after {
  content: "";
  position: absolute;
  top: -38.87px;
  left: 55%;
  transform: translate(-50%, 0);
  width: 8.97px;
  height: 8.97px;
  background-color: #959595;
  border-radius: 100%;
  transform: translate(-53.82px, 0);
}
.pca-hold .pca-main:before {
  content: "";
  position: absolute;
  top: -14.95px;
  left: 55%;
  opacity: 1;
  width: 59.8px;
  height: 2.99px;
  transform: translate(-26.91px, -22.425px);
  border-radius: 2.99px;
  background-color: #959595;
}
.pca-hold .pca-inner {
  display: block;
}
.pca-hold .pca-sub {
  display: block;
  text-align: center;
  position: relative;
  height: 0;
}
.pca-hold .pca-sub .pca-bot {
  border-radius: 100%;
  width: 41.86px;
  height: 41.86px;
  display: block;
  margin: 0 auto;
  background-color: transparent;
  z-index: 2;
  box-shadow: 0 0 0 3.289px #636670;
  transform: translate(0, -56.212px);
}
