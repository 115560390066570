.app {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 10px;
  float: left;
  width: 300px;
  height: 140px;
}
.app .app-name {
  display: block;
  font-size: 1.2em;
  line-height: 1.25em;
  margin-bottom: 0.5em;
}
.app .user {
  padding: 2px 4px;
  background-color: white;
  border: 1px solid #2f2f2f;
  border-radius: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
  font-size: 0.8em;
}
