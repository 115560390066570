.forgot-password-page {
  padding: 20px;

  position: relative;
  top: calc(50% - 50px);
  transform: translate(0, -50%);

  width: 400px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
}

.forgot-password-page > svg {
  align-self: center;
  margin-bottom: 20px;
}

.forgot-password-page h3 {
  margin-top: 0;
}

@media screen and (max-width: 639px) {
  .forgot-password-page {
    width: 100%;
  }
}
