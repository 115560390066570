.modal-fullscreen-holder {
  overflow: hidden;
}

.media-item-picker {
  /* For specificity */
}

.media-item-picker.modal-lg {
  width: 95%;
}

.media-item-picker {
  height: 90%;
}

.media-item-picker .modal-content {
  height: 100%;

  display: flex;
  flex-direction: column;
}

.media-item-picker .modal-content .modal-body {
  overflow-y: auto;

  display: flex;
  flex-direction: column;
}

.media-item-picker .modal-content .modal-body .tab-content {
  height: 100%;
}

.media-item-picker .modal-content .modal-body .tab-content .tab-pane {
  height: 100%;
  padding-top: 20px;
}

.media-item-picker .modal-content .modal-body .tab-content .tab-gallery {
  display: flex;
  flex-direction: column;
}

.media-item-picker
  .modal-content
  .modal-body
  .tab-content
  .tab-gallery
  .search-box {
  max-width: 300px;
  width: 100%;
}

.media-item-picker
  .modal-content
  .modal-body
  .tab-content
  .tab-gallery
  .gallery {
  height: 100%;
  margin-top: 15px;
  overflow-y: auto;
}

.media-item-picker .modal-content .modal-footer {
  margin-top: auto;
}

.media-item .thumb-holder {
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
}

.media-item .thumbnail {
  height: 60px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
