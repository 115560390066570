.apps-list .app-group {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #dddddd;
}

.apps-list .app-group:last-child {
  border-bottom: none;
  margin-bottom: 100px;
}

.apps-list .app {
  width: 250px;
  height: 140px;
  background-color: #eff1f4;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 10px;

  position: relative;
}

.apps-list .app .background {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  pointer-events: none;
}

.apps-list .app a {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.apps-list .app a:hover {
  text-decoration: none;
}

.apps-list .app .title {
  flex-grow: 1;
}

.apps-list .app .app-name {
  font-size: 1.2em;
}

.apps-list .app .app-devices {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.apps-list .app .app-devices .bullet {
  color: green;
  font-size: 2em;
  margin-right: 5px;
}

.apps-list .app .app-type-icon {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  color: #337ab7;
  margin-left: auto;
}

.apps-list .app .app-type-icon i {
  font-size: 20px;
  margin-left: 10px;
  padding-bottom: -5px;
  display: inline-block;
}

.apps-list .app .edit-status {
  color: green;
  font-weight: bold;
  font-style: italic;
  font-size: 0.8em;
}

.apps-list .app .app-status {
  display: flex;
  flex-direction: row;
}

ul.apps-list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 20px;
}

ul.apps-list li {
  margin-bottom: 20px;
  padding: 10px 5px;
}

ul.apps-list li img {
  width: 100px;
  height: 70px;
  background-size: cover;
  margin-right: 12px;
}

.pages {
  width: 300px;
  height: 100%;
  overflow: auto;
  position: relative;
}

.pages-title {
  margin-top: 1em;
  margin-bottom: 1em;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}
/*
    PAGE EDITOR
*/

.page-editor {
  width: 100%;
  padding: 0px 12px;
  height: 100%;
  max-width: 700px;
  overflow: auto;
  position: relative;
}

.page-preview {
  padding: 20px;
}

@media (max-width: 1200px) {
  .page-editor {
    max-width: none;
    max-width: initial;
  }
  .page-preview {
    display: none;
  }
}

.page-editor .nav-tabs {
  margin-left: 15px;
}

.page-name-holder {
}

.page-name {
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 24px;
  padding-bottom: 1px;
  margin-top: 1em;
  margin-bottom: 1em;
  font-family: 'Open sans', Arial, Helvetica, sans-serif;
}

.page-name:hover {
  border-bottom: 1px solid #2d7e9a;
  padding-bottom: 0;
}

.page-name-holder .page-template {
  display: none;
}
.page-name:hover .page-template {
  display: inline;
}

.edit-block {
  margin-bottom: 3em;
  position: relative;
}

.edit-block .edit-block-commands {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.edit-block:hover .edit-block-commands {
  opacity: 1;
}

.edit-block-title {
  border-bottom: 1px solid #2d7e9a;
  padding-bottom: 0.4em;
  text-transform: lowercase;
  font-weight: 100;
  font-family: 'Open sans';
}

.edit-block legend {
  font-size: 12px;
  color: #929d9d;
  letter-spacing: 0;
  line-height: 12px;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
}

.edit-block-commands button {
  margin-left: 5px;
}

.btn-save-page {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1000;
}

.page__actions {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1000;
}

.page__actions .btn {
  margin-left: 4px;
}

.tab-content {
}

.subtle-label {
  font-size: 10px;
  color: #929d9d;
  letter-spacing: 0;
  line-height: 12px;
  text-transform: uppercase;
  font-weight: normal;
}

/*
    LOCALIZATION
*/

.unchanged {
  font-style: italic;
  opacity: 0.6;
}

/*
    GALLERY
*/

.upload,
.gallery-image,
.thumbnail-image {
  width: 140px;
  height: 140px;
  outline: none;
  background: transparent;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  margin: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: deepskyblue;
  background-color: #ffffff;
  text-align: center;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload:hover {
  background-color: #eaeaea;
}

.gallery-image,
.thumbnail-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.gallery-image button,
.thumbnail-image button {
  display: none;
  outline: none;
  border: none;
  background: rgba(0, 0, 0, 0.5);
  color: red;
  font-size: 2em;
  width: 139px;
  height: 139px;
}

.gallery-image:hover button,
.thumbnail-image:hover button {
  display: block;
}

.upload span {
  font-size: 70px;
  margin-top: -12px;
}

.upload-accept {
  background-color: deepskyblue;
  color: white;
}

.upload-reject {
  background-color: darkred;
  color: white;
}

.uploaded-thumb {
  float: left;
  max-width: 200px;
  max-height: 140px;
}

.btn-remove-highlight {
  background: transparent;
  border: none;
  color: gold;
}

.btn-highlight {
  background: transparent;
  border: none;
  color: gold;
}

.btn-remove-page {
  background: transparent;
  border: none;
  color: red;
}

.page-editor .children {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.page-editor .children li {
  margin-bottom: 8px;
  display: block;
  padding: 4px 8px;
}

.page-editor .children li .fa {
  margin-right: 8px;
}

.page-editor .tab-pane {
  margin-top: 20px;
  margin-left: 12px;
}

.template-selector .template {
  background-color: #fafafa;
  padding: 4px 12px;
  margin-bottom: 4px;
}

.template-selector .selected {
  font-weight: bold;
  background-color: #dedede;
}

.fancy .control-label {
  font-size: 10px;
  color: #929d9d;
  letter-spacing: 0;
  line-height: 12px;
  text-transform: uppercase;
}

.fancy .control-info {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 20px;
}

.fancy .checkbox label {
  font-size: 12px;
  line-height: 20px;
  color: #929d9d;
  letter-spacing: 0;
  text-transform: uppercase;
}
