.reset-password-container {
  padding: 20px;

  position: relative;
  top: calc(50% - 50px);
  transform: translate(0, -50%);

  max-width: 300px;
  margin: 0 auto;
}

.reset-password-container h3 {
  margin-top: 0;
}
