.playlist-name {
  font-family: 'Open Sans';
  font-size: 30px;
  color: #2d7e9a;
  letter-spacing: 0;
  line-height: 30px;
}

.playlist-info-cell {
  padding: 30px 0 30px 32px;
  float: left;
}
.playlist-info-cell:first-child {
  padding: 30px 0 30px 41px;
}

.playlist-info-cell .value {
  display: block;
  font-family: 'Open Sans';
  font-size: 16px;
  color: #2d7e9a;
  letter-spacing: 0;
  line-height: 30px;
}

.playlist-info-cell select {
  height: 30px;
  width: 166px;
  background: #ffffff;
  border: 1px solid #c2c5cb;
  box-shadow: inset 0 0 7px 0 rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}

body > tr {
  display: table !important;

  td {
    padding: 8px;
    line-height: 1.428571429;
    vertical-align: top;
    border-top: 1px solid #ddd;
  }
}

.sorting-row {
  background-color: white;
  box-shadow: 1px 1px 10px black;
  height: 70px;
}

.sorting-row td {
  padding: 8px;
  vertical-align: middle;
}
