.pages-tree {
  padding: 0;
  list-style-type: none;
  font-size: 1.1em;
}

.pages-tree li > a {
  padding: 4px 8px;
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pages-tree a:active,
.pages-tree a:hover,
.pages.tree a:focus,
.pages.tree a:visited {
  text-decoration: none;
}

.pages-tree .fa {
  margin-right: 8px;
}

.pages-tree .selected {
  color: black;
  font-weight: bold;
  background-color: rgb(246, 246, 246);
}

.pages-tree .page {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.pages-tree .page > .fa-link {
  margin-left: 10px;
}

.pages-tree .page-edited {
  font-style: italic;
}

.pages-tree .page-hidden {
  color: gray;
}

.pages-tree .page-edited:before {
  content: 'EDIT';
  font-weight: bold;
  color: green;
  float: right;
  font-size: 0.7em;
}
