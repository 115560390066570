html {
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  /* padding-top: 50px; */
  background: #f6f6f6;
}

a {
  cursor: pointer;
}

.loading {
  line-height: 60px;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -30px;
  text-align: center;
  width: 100%;
}

.center-box {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-left: -200px;
  height: 100px;
  line-height: 100px;
  margin-top: -50px;
}

.full-height {
  height: 100%;
}

#root {
  height: 100%;
  overflow: auto;
}

.padding0 {
  padding: 0;
}

.container,
.root-container {
  color: #2d7e9a;
  height: 100%;
}

.root-container {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.navbar {
  background-image: linear-gradient(-179deg, #32596d 0%, #183f4a 100%);
  height: 50px;
}

.navbar-inverse .navbar-brand {
  /*font-family: AzoSans-Regular;*/
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0.26px;
  line-height: 15px;
}

.navbar-inverse .navbar-nav > li > a {
  /*font-family: AzoSans-Regular;*/
  font-size: 13px;
  color: #929d9d;
  letter-spacing: 0.21px;
  line-height: 15px;
  text-transform: uppercase;
  padding-top: 18px;
  padding-bottom: 17px;
}

.navbar-inverse .navbar-nav > li > a.link-active {
  font-size: 13px;
  color: #00caca;
  letter-spacing: 0.21px;
  line-height: 15px;
}

.navbar-inverse .navbar-nav > li > a.link-active:after {
  content: ' ';
  height: 2px;
  width: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  border-bottom: 2px solid #00caca;
  box-shadow: 0 -2px 5px 0 rgba(0, 243, 255, 0.5);
}

#navigation-holder {
  padding: 0 45px 0 15px;
}

.main-section {
  padding: 15px;
  margin-right: 32px;
  margin-bottom: 32px;
  width: 220px;
  height: 50px;
  float: left;
}

.card-bg {
  background-image: linear-gradient(-180deg, #ffffff 0%, #eff1f4 100%);
  border: 1px solid #ffffff;
  box-shadow: 0 1px 1px 0 rgba(146, 157, 157, 0.5);
  border-radius: 3px;
}

.card-bg:hover {
  box-shadow: 0 1px 1px 0 rgba(146, 157, 157, 1);
}

.main-section a {
  line-height: 15px;
  font-size: 15px;
  text-align: center;
}

.addmediathumb {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px;
  text-align: center;
  width: 100px;
  height: 100px;
  overflow: hidden;
}

.media-thumb {
  width: 100px;
  height: 70px;
  background-size: cover;
}

.btn-fancy {
  color: #929d9d;
  background-image: linear-gradient(-180deg, #ffffff 0%, #eff1f4 100%);
  border: 1px solid #ffffff;
  box-shadow: 0 1px 2px 0 rgba(146, 157, 157, 0.5);
  border-radius: 3px;
}

.btn-fancy:hover,
.btn-fancy:active,
.btn-fancy:focus,
.btn-fancy:visited {
  outline: none;
}

.btn-transparent {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}

.btn-transparent:hover,
.btn-transparent:active,
.btn-transparent:focus,
.btn-transparent:visited {
  outline: none;
}

.btn-transparent:disabled {
  color: #a0a0a0;
  cursor: not-allowed;
}

.navbar .btn {
  line-height: 15px;
  margin: 10px 0 5px 10px;
  color: white;
  background: transparent;
  border: 1px solid white;
}

.table-shadow {
  background: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 3px;
}

.table-shadow th {
  opacity: 0.54;
  /*font-family: Roboto-Medium;*/
  font-size: 12px;
  font-weight: normal;
  color: #000000;
  letter-spacing: 0;
}

.table-shadow td {
  color: black;
  font-weight: normal;
  font-size: 13px;
}

.thumb {
  width: 100px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.thumb img {
  max-width: 100px;
  max-height: 70px;
}

.table > tbody > tr > td,
.table > tbody > tr > th {
  vertical-align: middle;
}

.top-options {
  padding: 20px 0;
}

.drag-handle {
  cursor: pointer;
}

.mr-20 {
  margin-right: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.pb-0 {
  padding-bottom: 0;
}

/*/////////////////////////////////////////////////////////////////////////
                        Digital Signage CSS
////////////////////////////////////////////////////////////////////////*/
.app-holder {
  padding-top: 50px;
}

.app-holder,
.digital-signage-holder {
  width: 100%;
  background: #f6f6f6;
  color: #fff;
  height: 100%;
}

.digital-content-holder {
  padding: 0 20px;
  height: 100%;
}

.builder-container {
  height: 100%;
}

.canvas-holder input {
  color: #000;
}

.canvas-holder {
  height: calc(100% - 150px);
  transition: height 0.5s;
}

.canvas-holder.stretch {
  height: 100%;
}

.canvas-container {
  margin: auto;
}

.canvas-section-holder {
  width: calc(100% - 351px);
  float: left;
  background: #999 url('../Images/signage/transparent-pattern.gif');
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  height: calc(100% - 65px);
}

.canvas-controls {
  width: 341px;
  float: left;
  margin-left: 10px;
  height: calc(100% - 65px);
}

.top-controls,
.bottom-controls {
  padding: 10px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.bottom-controls {
  overflow: auto;
  color: #929d9d;
  height: calc(100% - 60px);
}

.top-controls {
  margin-bottom: 15px;
}

.digital-content-holder .navbar-default {
  margin-bottom: 0;
}

.name-and-save {
  padding: 20px 0;
  margin-bottom: 10px;
}

.name-and-save .glyphicon-pencil {
  color: #929d9d;
  vertical-align: top;
}

.name-and-save input {
  background: none;
  border: none;
  outline: none;
  min-width: 300px;
  font-size: 20px;
  color: #2d7e9a;
  letter-spacing: 0;
  line-height: 30px;
}

.name-and-save .save-button {
  color: #929d9d;
  background-image: linear-gradient(-180deg, #ffffff 0%, #eff1f4 100%);
  border: 1px solid #ffffff;
  box-shadow: 0 1px 2px 0 rgba(146, 157, 157, 0.5);
  border-radius: 3px;
}

.name-and-save .save-button:hover {
  color: #344;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #2d7e9a;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #2d7e9a;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #2d7e9a;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #2d7e9a;
}

.single-channel {
  background-image: linear-gradient(-180deg, #ffffff 0%, #eff1f4 100%);
  border: 1px solid #ffffff;
  box-shadow: 0 1px 1px 0 rgba(146, 157, 157, 0.5);
  border-radius: 4px;
  margin-right: 32px;
  margin-bottom: 32px;
}

.channels-holder .col-md-3 {
  margin-bottom: 20px;
}

.modal-content {
  color: #000;
}

.modal-dialog-fullscreen {
  width: 80%;
  height: 80%;
  margin: 0;
  padding: 0;
  margin: auto;
  top: 10%;
}

.modal-dialog-fullscreen .modal-content {
  height: auto;
  min-height: 80%;
  border-radius: 0;
}

.schedule-day {
  display: inline-block;
  background-color: white;
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: black;
  text-align: center;
  border: 1px solid #eee;
}

.schedule-day.selected {
  background-color: green;
  color: white;
}

/*/////////////////////////////////////////////////////////////////////////
                        Timeline CSS
/////////////////////////////////////////////////////////////////////////*/
.timeline-container {
  background: #fff;
  border-radius: 3px;
  color: #929d9d;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  height: 200px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  transition: height 0.5s;
}

.timeline-container.collapsed {
  height: 40px;
  overflow: hidden;
}

.timeline-container.collapsed .timeline-objects-holder {
  display: none;
}

.timeline-container .collapse-btn {
  position: fixed;
  right: 10px;
  padding-top: 0px;
  display: inline-block;
  background: transparent;
  outline: none;
  z-index: 100;
}

.timeline-container .collapse-btn:active,
.timeline-container .collapse-btn:hover,
.timeline-container .collapse-btn:visited {
  color: red;
  outline: none;
}

.timeline-right-object {
  width: 100px;
  height: 32px;
  background: #2d7e9a;
  line-height: 32px;
  text-align: center;
  margin-left: 0;
  border-radius: 3px;
}

.selected-obj {
  background: #f6f6f6;
}

.timeline-left p:hover {
  cursor: pointer;
}

.timeline-duration-summ {
  margin: 12px auto;
}

/*/////////////////////////////////////////////////////////////////////////
                        Object properties and actions CSS
/////////////////////////////////////////////////////////////////////////*/
.top-controls button {
  margin-right: 10px;
}

.single-control-holder label,
.single-control-holder input,
.single-control-holder select {
  width: 70px;
}

.single-control-holder .textarea-label {
  float: left;
  margin-top: 5px;
}

.single-control-holder textarea {
  width: 200px;
  margin-right: 30px;
  border-radius: 2px;
  outline: none;
  padding: 2px 4px;
  border: 1px solid #cecece;
  background: #f5f5f5;
  color: dodgerblue;
  box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.18);
  font-size: 12px;
  color: #2d7e9a;
}

.single-control-holder {
  padding: 10px;
}

.image-btn,
.rect-btn,
.circle-btn,
.text-btn,
.line-btn {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
}

.btn-clear {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0;
}

.separator {
  display: inline-block;
  width: 1px;
  height: 2.5rem;
  background: #cecece;
  vertical-align: middle;
  margin-right: 10px;
  opacity: 0.7;
}

.image-btn {
  background: #f3f3f3 url('../Images/signage/icon_image-btn.png') no-repeat
    center;
}

.rect-btn {
  background: #f3f3f3 url('../Images/signage/icon_rectangle-btn.png') no-repeat
    center;
}

.line-btn {
  background: #f3f3f3 url('../Images/signage/icon_line-btn.png') no-repeat
    center;
}

.circle-btn {
  background: #f3f3f3 url('../Images/signage/icon_oval-btn.png') no-repeat
    center;
}

.text-btn {
  background: #f3f3f3 url('../Images/signage/icon_font-btn.png') no-repeat
    center;
}

.single-control-holder input,
.single-control-holder select {
  margin-right: 30px;
  border-radius: 2px;
  outline: none;
  padding: 2px 4px;
  border: 1px solid #cecece;
  background: #f5f5f5;
  color: dodgerblue;
  box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.18);
  font-size: 12px;
  color: #2d7e9a;
  line-height: 12px;
}

.bottom-properties div {
  width: 70px;
  float: left;
  text-align: center;
}

.bottom-properties p {
  margin: 0;
}

.bottom-properties div:last-child {
  margin-left: 30px;
}

.bottom-controls hr {
  margin: 10px auto;
  width: 90%;
}

.object-actions button {
  margin-right: 10px;
}

/*/////////////////////////////////////////////////////////////////////////
                        Media Gallery CSS
/////////////////////////////////////////////////////////////////////////*/

.media-upload {
  border: 1px dashed lightgray;
  padding: 10px;
  width: 80%;
  float: left;
}

.gallery-top-options {
  padding: 20px 0;
}

.gallery-holder {
  background: #fff;
  color: #000;
}

.gallery-holder .table td {
  vertical-align: middle;
}

.gallery-holder .table img {
  max-width: 120px;
  vertical-align: middle;
}

#user-actions {
  float: right;
  height: 50px;
  line-height: 50px;
}

#user-actions button {
  background-color: transparent;
  border: none;
  font-size: 15px;
  color: #ffffff;
  line-height: 15px;
}

#user-actions ul {
  padding: 10px;
}

#user-actions ul li a {
  color: white;
}

#logoutForm {
  width: 100%;
  float: none;
}

#logoutForm button {
  width: 100%;
  color: white;
}

.user-menu {
  background-image: linear-gradient(-179deg, #32596d 0%, #183f4a 100%);
  color: white;
}

form.dev {
  padding: 5px;
  background: #ddd;
  box-shadow: 5px 2px 2px #333;
}

.delete-media {
  margin-right: 10px;
  transition: transform 0.35s;
}

.delete-media:hover {
  cursor: pointer;
  transform: scale(1.5);
}

/*/////////////////////////////////////////////////////////////////////////
                        Playlists CSS
/////////////////////////////////////////////////////////////////////////*/

.playlists-top-options {
  padding: 20px 0;
}

#createScheduleForm {
  padding: 10px;
}

.rc-time-picker-input {
  border: none;
  background-color: transparent;
  padding: 0;
  height: auto;
}

.name {
  color: #2d7e9a;
}

.table-playlist tr.disabled {
  background-color: #eeeeee;
}

.duration-override-holder {
  display: none;
  position: absolute;
  background: #eee;
  border-radius: 3px;
  padding-left: 3px;
  top: 50%;
  left: 0;
  margin-top: -12px;
}

.duration-override-holder button {
  background: none;
  border: none;
  outline: none;
  color: red;
}

.duration-override-cell {
  position: relative;
}

.duration-override-cell:hover .duration-override-holder {
  display: block;
}

.duration-override-cell:hover .duration {
  display: none;
}

.duration-override {
  background: none;
  border: none;
  outline: none;
  width: 60px;
}

/*/////////////////////////////////////////////////////////////////////////
                        Screens CSS
/////////////////////////////////////////////////////////////////////////*/
.screen-left-border.green {
  background: #59be84;
}

.screen-left-border.yellow {
  background: yellow;
}

.screen-left-border.red {
  background: #be5959;
}

.screens-platform-icon {
  margin: 0;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  top: 110px;
}

.screen-content {
  float: none;
}

.screen-info {
  position: absolute;
  bottom: 0;
}

.screen-info img {
  margin-right: 4px;
  margin-top: -2px;
}

.screen-info .player-version {
  margin-right: 4px;
  display: inline-block;
  width: 12px;
  text-align: center;
  line-height: 11px;
  font-size: 11px;
}

/*/////////////////////////////////////////////////////////////////////////
                        Shell CSS
/////////////////////////////////////////////////////////////////////////*/

.notifications {
  position: fixed;
  right: 10px;
  bottom: 0;
  width: 400px;
}

.top-options {
  padding: 20px 0;
}

.recurring-schedule-holder button {
  background: transparent;
  padding: 5px 10px;
  border: 1px solid;
  border-radius: 3px;
  margin-right: 10px;
}

.recurring-schedule-holder button.pressed {
  background: blue;
  padding: 5px 10px;
  border: 1px solid;
  color: #fff;
  border-radius: 3px;
  margin-right: 10px;
}

/*/////////////////////////////////////////////////////////////////////////
                        Loading indicator CSS
                        http://tobiasahlin.com/spinkit/
/////////////////////////////////////////////////////////////////////////*/

.sk-cube-grid {
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #333;
  float: left;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube2 {
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube3 {
  animation-delay: 0.4s;
}
.sk-cube-grid .sk-cube4 {
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube5 {
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube6 {
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube7 {
  animation-delay: 0s;
}
.sk-cube-grid .sk-cube8 {
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube9 {
  animation-delay: 0.2s;
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    transform: scale3D(1, 1, 1);
  }
  35% {
    transform: scale3D(0, 0, 1);
  }
}

/*/////////////////////////////////////////////////////////////////////////
                            Responsive CSS
/////////////////////////////////////////////////////////////////////////*/
@media (max-width: 1200px) {
}

@media (min-width: 1200px) and (max-width: 1400px) {
}

@media (min-width: 1400px) and (max-width: 1600px) {
}

@media (min-width: 1600px) and (max-width: 1900px) {
}

.addmediathumb img {
  width: 51px;
  height: 51px;
}

.top-options {
  border-bottom: 1px solid #eee;
}

/*
    Modal forms
*/

.modal-fancy .modal-title {
  font-size: 26px;
  color: #2d3242;
  letter-spacing: 0;
  line-height: 26px;
}

.modal-fancy .modal-header {
  border-bottom: none;
}

.modal-fancy .modal-footer {
  border-top: none;
}

.modal-fancy .control-label {
  font-size: 10px;
  color: #929d9d;
  letter-spacing: 0;
  line-height: 12px;
  text-transform: uppercase;
}

.modal-fancy .checkbox label {
  font-size: 12px;
  line-height: 20px;
  color: #929d9d;
  letter-spacing: 0;
  text-transform: uppercase;
}

.modal-fancy .form-control {
  background: #ffffff;
  border: 1px solid #c2c5cb;
  box-shadow: inset 0 0 7px 0 rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}

.modal-fancy .modal-content {
  background-image: linear-gradient(-180deg, #ffffff 0%, #eff1f4 100%);
  border: 1px solid #ffffff;
  box-shadow: 0 1px 1px 0 rgba(146, 157, 157, 0.5);
  border-radius: 4px;
}

.rangeslider-horizontal {
  height: 4px;
  border-radius: 3px;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 13px;
  height: 13px;
  top: -4px;
  background: #2d7e9a;
}

.rangeslider-horizontal .rangeslider__handle:after {
  display: none;
}

.rangeslider-horizontal .rangeslider__fill {
  background: #929d9d;
}

.error-div {
  padding: 10px;
  margin-top: 20px;
  font-weight: bold;
  width: 50%;
}

span.media-draft {
  font-style: italic;
  font-weight: bold;
  color: orange;
}

.remove-button {
  background: transparent;
  outline: none;
}

.remove-button:focus,
.remove-button:active {
  outline: none;
}

.remove-button span {
  color: red;
  transform: scale(1);
  transition: transform 0.5s;
}

.remove-button span:hover {
  transform: scale(1.5);
}

/*
    BOOTSTRAP OVERRIDES
*/

.nav-tabs > li > a {
  text-transform: uppercase;
  /*font-family: AzoSans-Regular;*/
  font-size: 13px;
  color: #cecece;
  letter-spacing: 0;
  line-height: 26px;
  padding: 5px;
  min-width: 120px;
  text-align: center;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  border-bottom-color: #2d7e9a;
  color: #2d7e9a;
}

.ReactModalPortal {
  padding-top: 10px;
}

.ReactModal__Overlay {
  background-color: rgba(66, 66, 100, 0.5);
  z-index: 1060;
}

.modal-dialog {
  margin-top: 60px;
}

.pt-16 {
  padding-top: 16px;
}

.table tr.debug td {
  font-size: 0.8em;
  color: #333;
}

.line-loader {
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
}
.line-loader:before {
  display: block;
  position: absolute;
  content: '';
  left: -200px;
  width: 200px;
  height: 4px;
  background-color: #2980b9;
  animation: loading 2s linear infinite;
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}

/* .table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th, */
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  vertical-align: center;
}

.react-tooltip-lite {
  background: #333;
  color: white;
}

.react-tooltip-lite-arrow {
  border-color: #333;
}

.tooltip--error .react-tooltip-lite {
  color: #fafbfc;
  background-color: #cc0000;
}

.tooltip--error .react-tooltip-lite-arrow {
  border-color: #cc0000;
}


/*
  Ensure all tables have it correctly centered
  Override bootstrap style
*/
.table thead tr th {
  vertical-align: middle;
}
