.playlist-position-holder > span {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border: 1px solid #ddd;
  text-align: center;
  margin-right: 2px;
  cursor: pointer;
}
.playlist-position-holder > span:hover {
  background-color: #aaa;
}
.playlist-position-holder > span.selected {
  background-color: #ccc;
  color: blue;
  cursor: default;
}
